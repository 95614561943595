<template>
  <div class="index-content">
    <div class="scrollbar">
      <!-- 产品中心 -->
      <div class="content-bg">
        <div class="wrapper-content">
          <div class="in_prod">
            <div class="in_prod-item">
              <el-image
                class="in_prod-img"
                :src="require('@/assets/image/port (1).png')"
              ></el-image>
              <h2>体育赛事</h2>
              <h5>体育赛事体育赛事体育赛事</h5>
              <p>
                体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事
              </p>
            </div>
            <div class="in_prod-item">
              <el-image
                class="in_prod-img"
                :src="require('@/assets/image/port (2).png')"
              ></el-image>
              <h2>培训教学</h2>
              <h5>培训教学培训教学</h5>
              <p>
                培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学培训教学
              </p>
            </div>
            <div class="in_prod-item">
              <el-image
                class="in_prod-img"
                :src="require('@/assets/image/port (3).png')"
              ></el-image>
              <h2>体育活动</h2>
              <h5>体育赛事体育赛事体育赛事</h5>
              <p>
                体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事
              </p>
            </div>
            <div class="in_prod-item">
              <el-image
                class="in_prod-img"
                :src="require('@/assets/image/port (4).png')"
              ></el-image>
              <h2>场馆服务</h2>
              <h5>体育赛事体育赛事体育赛事</h5>
              <p>
                体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事体育赛事
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-index",
  components: {},
  data() {
    return {};
  },
  mounted() {
    // this.init();
    this.$store.dispatch("saveHeadActive", "/product");
  },
  methods: {
    init() {
      const self = this;
      let sign = self.sign;
      self.$store.commit("showLoading");

      getHome({ sign }).then((res) => {
        if (res.code == 200) {
          self.$store.commit("hideLoading");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    onSearch() {},
  },
};
</script>

<style lang="scss" scoped>
</style>
